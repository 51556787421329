<template>
  <div class="page contact">
    <h1 class="center">Contacteer ons</h1>
    <div class="contact__content">
      <p class="center">Meer weten over onze producten en service of wenst u meer informatie over onze oplossingen op maat? Wij beantwoorden al uw vragen. Neem contact op via
        onderstaande gegevens en wij helpen u zo snel mogelijk verder!</p>
      <h2 class="center">Bio Degradable Packaging</h2>
      <table class="table">
        <tr>
          <td>Adres</td>
          <td>
            <p>Wiedauwkaai 99B</p>
            <p>9000 Gent</p>
            <p>België</p>
          </td>
        </tr>
        <tr>
          <td>Tel</td>
          <td><a href="tel:+32472940227">+32 472 94 02 27</a></td>
        </tr>
        <tr>
          <td>Email</td>
          <td><a href="mailto:contact@biodp.eu">contact@biodp.eu</a></td>
        </tr>
        <tr>
          <td>Btw</td>
          <td>BE0886412625</td>
        </tr>
        <tr>
          <td>Ondernemingsnr.</td>
          <td>0886412625</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact"
}
</script>