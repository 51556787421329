<template>
  <div class="page products">
    <h1 class="center">Bekijk ons uitgebreid assortiment</h1>
    <div class="products__list">
      <ProductsItem v-for="item in products" :product="item" :key="item.PROD_ID"></ProductsItem>
    </div>
  </div>
</template>

<script>
import ProductsItem from "@/components/products/ProductsItem";
export default {
  name: "Products.vue",
  components:{
    ProductsItem
  },
  data(){
    return {
      products:[]
    };
  },

  created(){
    this.fetchProducts();
  },

  methods: {
    fetchProducts(){
      fetch('https://biodp.eu/erp/api/products/productlist.php')
      .then((response)=>{
        if(response.ok){
          return response.json();
        }
      })
      .then((data)=>{
        this.products = data.data;
      });
    }
  }
}
</script>