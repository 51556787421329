<template>
  <div class="products__list__item">{{product.PROD_ID}}</div>
</template>

<script>
export default {
  name: "ProductsItem",
  props: {
    product: {required: true}
  }
}
</script>